import Vue from "vue";
export default Vue.extend({
    name: "AudienceSideInfo",
    props:{
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      reach: {
        type: Number,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components:{},
    data: () => ({}),
    created(){},
    mounted(){},
    computed:{
    },
    methods:{}
});