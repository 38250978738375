import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { InfoData } from "@/interfaces/export";
import AmchartGlobal from "./global";
import { translateChartIfExist } from "@/utils/Locale";

class AmchartFunnelBar {

	root: any = null;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

	async setup(params: {
		id: string;
		infoData: InfoData;
		source: Array<any>;
	}) {

		await this.dispose(params.id);

		if (!AmchartGlobal.isPassedSource(params.infoData.id, params.source))
			return;

		this.root = am5.Root.new(params.id);

		const data = params.source.map(item => {
			return {
				translation: translateChartIfExist(params.infoData.id, item.name),
				...item
			}
		})

		const myTheme = am5.Theme.new(this.root);
		myTheme.rule("ColorSet").set("colors", [
			am5.color("#579BCE"),
			am5.color("#62A2D1"),
			am5.color("#6DA9D5"),
			am5.color("#78B0D8"),
			am5.color("#83B7DC"),
			am5.color("#8EBEDF"),
			am5.color("#9AC6E3"),
			am5.color("#A5CDE6"),
			am5.color("#B0D4EA"),
			am5.color("#BBDBED"),
			am5.color("#C6E2F1"),
			am5.color("#D1E9F4"),
		])

		this.root.setThemes([
			am5themes_Animated.new(this.root),
			myTheme
		]);

		// Create chart
		var chart = this.root.container.children.push(am5percent.SlicedChart.new(this.root, {
			layout: this.root.horizontalLayout,
		}));

		// Add series
		function makeSeries(name: string, fieldName: string, root: am5.Root) {

			let series = chart.series.push(am5percent.FunnelSeries.new(root, {
				name: name,
				alignLabels: true,
				orientation: "vertical",
				valueField: "uniques",
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: "vertical",
					labelText: "[bold]{type}[/]\n{translation}: [bold]{valueField}",
				}),
				categoryField: "name",
				legendValueText: "{uniques}",
			}));

			series.events.on("datavalidated", function (ev: { target: any }) {
				let series = ev.target;
				let chart = series.chart;

				chart.root.dom.style.height = 400 + "px";
			});

			series.links.template.setAll({
				fill: am5.color(0x47A0D3),
				fillOpacity: 0.2,
				height: 5
			});

			series.slices.template.setAll({
				fillOpacity: 1,
				// fill: am5.color(0x67B7DC),
				strokeOpacity: 0,

			});

			series.labels.template.setAll({
				text: `{translation}: {uniques}`
			})

			series.data.setAll(data);

			// Make stuff animate on load
			series.appear();

			//legend.data.push(series);
			// var legend = chart.children.push(am5.Legend.new(root, {
			// 	y: am5.percent(50),
			// 	centerY: am5.percent(50),
			// 	layout: root.verticalLayout,
			// 	marginLeft: 15,
			// 	marginRight: 15
			// }));

			// legend.data.setAll(series.dataItems);
		}

		makeSeries("Funnel", "name", this.root);

		this.load(chart);

		// if(omitExportPDF().includes(params.infoData.id)) return;

		// Add export menu
		this.exporting = await AmchartGlobal.enableExporting(
			params.id,
			this.root,
			params.source
		);

		/**
		 * Se agrega la informacion del grafico para incluir en la generacion del pdf
		 */
		AmchartGlobal.addExporting(
			await AmchartGlobal.prepareExportParams(params, this.exporting)
		);
	}

	async load(chart: am5percent.FunnelSeries) {
		chart.appear(500, 100);
	}
}

export default new AmchartFunnelBar();
