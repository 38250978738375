import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { InfoData } from "@/interfaces/export";
import AmchartGlobal from "./global";
import * as am5xy from "@amcharts/amcharts5/xy";
import { newYAxisRenderer } from ".";
import { translateChartIfExist } from "@/utils/Locale";

class AmchartCombined {

	root?: am5.Root;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

	async setup(params: {
		id: string;
		infoData: InfoData;
		source: Array<any>;
	}) {

		await this.dispose(params.id);

		if (!AmchartGlobal.isPassedSource(params.infoData.id, params.source))
			return;

		this.root = am5.Root.new(params.id);

		this.root.setThemes([
			am5themes_Animated.new(this.root)
		]);

    let totalUniques = 0;
    let max = 0
    params.source.forEach(item => { 
      totalUniques += item.uniques;

      if(max < item.uniques) max = item.uniques
    });

    let data = params.source.map(item => {
      let newData = {
        name: item.name,
        translation: translateChartIfExist(params.infoData.id, item.name),
        uniques: item.uniques,
        bulletSettings: {
          // svgPath: item.svg,
          src: item.src
        },
        default: 100,
        percent: ((item.uniques / totalUniques) * 100).toFixed(0)
      }

      return newData;
    });
    let height = (80 / data.length) + 20;

    let chart = this.root.container.children.push(
      am5xy.XYChart.new(
        this.root, {
        layout: this.root.verticalLayout,
        height: am5.percent(height > 80 ? 80 : height),
        panX: false,
        panY: false
      }
      )
    )

    let chart2 = this.root.container.children.push(
      am5xy.XYChart.new(
        this.root, {
        layout: this.root.horizontalLayout,
        height: am5.percent(100 - (height > 80 ? 80 : height)),
        y: am5.percent(height > 80 ? 80 : height),
        panX: false,
        panY: false
      }
      )
    )

    let yAxis2 = chart2.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, newYAxisRenderer),
        categoryField: "name"
      }) as any
    );
    yAxis2.get("renderer").labels.template.setAll({
      text: "{translation}",
      fontSize: 12,
      location: 0.5,
      oversizedBehavior: "truncate",
      maxWidth: 250,
      textAlign: "start",
      fontFamily: "Gotham",
      textTransform: "capitalize",
      textBaseline: "center",
      dx: -130,
    })
    yAxis2.data.setAll(data);

    let xAxis2 = chart2.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        visible: false,
        min: 0,
        max: max * 1.125,
        strictMinMaxSelection: true
      }) as any
    );

    yAxis2.get("renderer").grid.template.setAll({
      strokeOpacity: 0
    });
    xAxis2.get("renderer").grid.template.setAll({
      strokeOpacity: 0
    });
    
    function makeSeries(name: string, fieldName: string, root: am5.Root) {
      let series2 = chart2.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis2,
          yAxis: yAxis2,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "vertical",
            labelText: "[bold]{translation}[/]: {uniques}",
          }),
          valueXField: "uniques",
          categoryYField: "name"
        })
      );
      series2.data.setAll(data);
  
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          locationX: 0.5,
          sprite: am5.Label.new(root, {
            text: "{uniques}",
            fontSize: 12,
            fill: am5.color(0xffffff),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            populateText: true
          })
        });
      });    

      series2.columns.template.onPrivate("width", function(width, target) {
        if(target?.dataItem?.bullets) {
          am5.array.each(target.dataItem.bullets, function(bullet) {
            if ((bullet.get("locationX") || 0) < 1) {
              if ((width || 0) < 60) {
                bullet.get("sprite").hide();
              }
              else {
                bullet.get("sprite").show();
              }
            }
          });
        }
      });
      
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY:  0.5,
          locationX: 1,
          sprite: am5.Label.new(root, {
            text: "         {percent}%",
            fontSize: 15,
            fontWeight: "600",
            fontFamily: "Gotham",
            fill: am5.color(0x67B7DC),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            populateText: true
          })
        });
      });
      
      
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "default",
          categoryXField: "name",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "vertical",
            labelText: "[bold]{translation}[/]: {uniques}",
          }),
        })
      );

      series1.data.setAll(data.slice().reverse());
  
      series1.columns.template.setAll({
        fillOpacity: 0,
        strokeOpacity: 0
      })
  
      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: ((400 - (310)) / 400),
          locationX: 0.5,
          sprite: am5.Picture.new(root, {
            templateField: "bulletSettings",
            height: (240 / data.length) > 180 ? 180 : 240 / data.length,
            centerX: am5.p50,
            centerY: am5.percent(105),
          })
        });
      });
  
      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: ((400 - (10 * data.length)) / 400),
          locationX: 0.5,
          sprite: am5.Label.new(root, {
            text: "{translation}",
            fontSize: (50 / data.length) > 20 ? 20 : 50 / data.length,
            fontWeight: "600",
            fontFamily: "Gotham",
            fill: am5.color(0x444444),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            populateText: true
          })
        });
      });
  
      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY:  ((400 - (370)) / 400),
          locationX: 0.5,
          sprite: am5.Label.new(root, {
            text: "{percent}%",
            fontSize: (80 / data.length) > 30 ? 30 : 80 / data.length,
            fontWeight: "600",
            fontFamily: "Gotham",
            fill: am5.color(0x67B7DC),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            populateText: true
          })
        });
      });
  
      series1.events.on("datavalidated", function (ev: { target: any }) {
        let series = ev.target;
        let chart = series.chart;
        chart.root.dom.style.height = 400 + "px";
      });
      
      // Make stuff animate on load
      series2.appear(500, 100);
		}

    // Add cursor
    chart2.set("cursor", am5xy.XYCursor.new(this.root, {}));

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {}),
        min: 0,
        max: 100,
        visible: false
      }) as any
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        visible: false,
        categoryField: "name"
      }) as any
    );
    xAxis.data.setAll(data.slice().reverse());

    yAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0
    });
    xAxis.get("renderer").grid.template.setAll({
      strokeOpacity: 0
    });

		makeSeries("Distance", "name", this.root);

		this.load(chart);
		this.load(chart2);

		// if(omitExportPDF().includes(params.infoData.id)) return;

		// Add export menu
		this.exporting = await AmchartGlobal.enableExporting(
			params.id,
			this.root,
			params.source.map(item => ({ name: item.name, uniques: item.uniques }))
		);

		/**
		 * Se agrega la informacion del grafico para incluir en la generacion del pdf
		 */
		AmchartGlobal.addExporting(
			await AmchartGlobal.prepareExportParams(params, this.exporting)
		);
	}

	async load(chart: am5xy.XYChart) {
		chart.appear(500, 100);
	}
}

export default new AmchartCombined();