import { FloatingAction } from "@/interfaces/persons/v10/audience";
import { AudienceStrategy } from "@/models/persons/v10/Audience";
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
	name: "SectionButtons",
	model: {},
	props: {},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {
		...mapGetters("audience", [
			"getStrategy"
		]),

		getButtons() {
			const buttons: {
				key: string,
				icon: string,
				loading: boolean
			}[] = [];

			const strategy: AudienceStrategy = this.getStrategy;

			strategy.sections.map(section => {
				if(!section.selected) {
					buttons.push({
						icon: section.icon,
						key: section.name,
						loading: section.loading
					})
				}
			})

			return buttons;
		}
	},
	methods: {
		...mapActions("audience", [
			"postStrategySection"
		]),

    handleClick(action: string) {
			this.postStrategySection(action)
    }
  },
	watch: {},
});
