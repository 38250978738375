import Vue, { PropType } from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import { mapActions, mapGetters } from "vuex";
import PoisInformation from "../../PoisInformation/index.vue"
import Date from "../../Date/index.vue"
import { AudienceStrategy } from "@/models/persons/v10/Audience";

export default Vue.extend({
	name: "InformationSection",
	props: {
		sections: {
			type: Array as PropType<string[]>,
			required: true,
			default: () => []
		}
	},
	components: { CollapsePanel, PoisInformation, Date },
	data: () => ({
		loading: false
	}),
	created() { },
	mounted() {},
	computed: {
		...mapGetters("audience", [
			"getAudience",
			"getStrategy"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", {
			getCountry: "getCountry"
		}),

		graphics() {
			return [
				'category_poi', 'sub_category_poi', 'marca_poi', 'dpto_poi', 'city_poi', 'barrio_poi', 'date_of_week', 'time_of_day', 'date', 'time_of_day_of_week', 'frequency'
			]
		},

		getSection() {
			const strategy = this.getStrategy as AudienceStrategy;

			return strategy.sections.find(section => (
				this.sections.includes(section.name)
			))
		},

		showCard() {
			return this.getStrategy.hasKey(...this.graphics) as boolean
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter", "postStrategySection"
		]),		

		async handleClick() {
			try {
				if (!this.getSection || this.getSection.selected) return;
				this.loading = true;
				await this.postStrategySection(this.getSection?.name);
				this.loading = false;
			} catch (error) {
				console.error("DemoHomesSection::handleClick", error);
			}
		},
	},
	watch: {},
});
