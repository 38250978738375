var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showCard)?_c('div',[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('section-separator',{attrs:{"icon":"mdi-home-city-outline","title":_vm.$t('Persons10.panelAudience.titles.residence_place')}}),_c('v-row',{ref:"residenceplace"},[_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'residence_dpto',
					name: _vm.geoTranslate('states')
				},"loading":_vm.residence_dpto.loading,"filters":_vm.getFilters('residence_dpto'),"source":_vm.residence_dpto.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'residence_city',
					name: _vm.geoTranslate('cities')
				},"loading":_vm.residence_city.loading,"filters":_vm.getFilters('residence_city'),"source":_vm.residence_city.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
					id: 'residence_barrio',
					name: _vm.geoTranslate('neighborhoods')
				},"loading":_vm.residence_barrio.loading,"filters":_vm.getFilters('residence_barrio'),"source":_vm.residence_barrio.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }