import Vue from "vue";

import CardChart from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/index.vue";
import LineChart from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Line/index.vue";
import { ItemGraphic } from "@/interfaces/graphic";
import { orderBy } from "lodash";

export default Vue.extend({
	name: "CardLine",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#3a4972",
		},
		filters: {
			type: Array,
			default: function () {
				return [];
			},
		},
		source: {
			type: Array,
			default: function () {
				return [] as Array<ItemGraphic>;
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		hide_filter: {
			type: Boolean,
			default: false
		},
		datepicker: {
			type: Boolean,
      default: false
		}
	},
	components: { LineChart, CardChart },
	data: () => ({		
		sort: {
			label: "name" as "uniques" | "name",
			order: "asc" as "asc" | "desc"
		}
	}),
	created() {},
	mounted() {},
	computed: {
		sortedSource() {
			return orderBy(this.source, [this.sort.label], [this.sort.order])
		}
	},
	methods: {
		handleFilter(event: any) {
			this.$emit("filter", event);
		}
	},
	watch: {},
});
