import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import { InfoData } from "@/interfaces/export";
import AmchartGlobal from "./global";
import { newYAxisRenderer, optionsAxis } from ".";
import { translateChartIfExist } from "@/utils/Locale";

class AmchartLineBar {

	root: any = null;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

	async setup(params: {
		id: string;
		infoData: InfoData;
		source: Array<any>;
	}) {

		await this.dispose(params.id);

		if (!AmchartGlobal.isPassedSource(params.infoData.id, params.source))
			return;

		this.root = am5.Root.new(params.id);
		const data: any[] = [];

		params.source.forEach(item => data.push(Object.assign({}, {...item})))

		this.root.setThemes([
			am5themes_Animated.new(this.root)
		]);

		// Create chart
		var chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
			focusable: true,
			panX: false,
			panY: false,
			wheelX: "none",
			wheelY: "none",
			pinchZoomX: false,
			layout: this.root.verticalLayout
		}));

		var easing = am5.ease.linear;


		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		let xRenderer = am5xy.AxisRendererX.new(this.root, {});

		// Translation
		let dataTranslated = data.map(item => {
			let translation = translateChartIfExist(params.infoData.id, item.name)

			return { translation, ...item }
		})
		
		xRenderer.labels.template.setAll({
			text: "{translation}",
			location: 0.5
		});
		var xAxis = chart?.xAxes.push(
			am5xy.CategoryAxis.new(this.root, {
				...optionsAxis,
				categoryField: "name",
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(this.root, {
					labelText: "{translation}"
				})
			})
		);
		xAxis.data.setAll(dataTranslated);

		var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
			// logarithmic: true,
			min: 0,
			renderer: am5xy.AxisRendererY.new(this.root, {
				strokeOpacity: 0.1
			})
		}));

		// Add series
		function makeSeries(root: am5.Root) {

			var series = chart.series.push(am5xy.LineSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "uniques",
				categoryXField: "name",
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: "horizontal",
					labelText: "{valueY}"
				})
			}));

			series.fills.template.setAll({
				fillOpacity: 0.1,
				visible: true
			});

			series.strokes.template.setAll({
				strokeWidth: 1
			});


			// Set up data processor to parse string dates
			// https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
			// series.data.processor = am5.DataProcessor.new(root, {
			// 	dateFormat: "yyyy-MM-dd",
			// 	dateFields: ["name"]
			// });

			series.data.setAll(data);

			series.bullets.push(function () {
				var circle = am5.Circle.new(root, {
					radius: 2,
					fill: root.interfaceColors.get("background"),
					stroke: series.get("fill"),
					strokeWidth: 1
				})

				return am5.Bullet.new(root, {
					sprite: circle
				})
			});

			// Make stuff animate on load
			series.events.on("datavalidated", function (ev: { target: any }) {
				let series = ev.target;
				let chart = series.chart;
				// let xAxis = chart.xAxes.getIndex(0);

				// Calculate how we need to adjust chart height
				// let chartHeight =
				// 	series.data.length * cellSize +
				// 	xAxis.height() +
				// 	chart.get("paddingTop", 0) +
				// 	chart.get("paddingBottom", 20);

				// Set it on chart's container
				chart.root.dom.style.height = 400 + "px";
			});

			series.appear();
		}

		makeSeries(this.root);

		this.load(chart);

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		var cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {
			xAxis: xAxis,
			behavior: "none"
		}));
		cursor.lineY.set("visible", false);

		// add scrollbar
		chart.set("scrollbarX", am5.Scrollbar.new(this.root, {
			visible: false
		}));


		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100);

		// if(omitExportPDF().includes(params.infoData.id)) return;

		// Add export menu
		this.exporting = await AmchartGlobal.enableExporting(
			params.id,
			this.root,
			params.source
		);

		/**
		 * Se agrega la informacion del grafico para incluir en la generacion del pdf
		 */
		AmchartGlobal.addExporting(
			await AmchartGlobal.prepareExportParams(params, this.exporting)
		);
	}

	async load(chart: am5xy.XYChart) {
		chart.appear(500, 100);
	}
}

export default new AmchartLineBar();
