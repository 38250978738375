import Vue, { PropType } from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import SideInfo from "@/views/Admin/Persons/V10/Dependencies/Common/SideInfo.vue"
import CardFunnel from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Funnel/index.vue";
import { IFilterData } from "../../../../Audience";
import { mapActions, mapGetters } from "vuex";
import { translateChartIfExist } from "@/utils/Locale";
import { isArray } from "lodash";
import { FilterItem } from "@/interfaces/persons/v10/audience";

export default Vue.extend({
	name: "AudiencePois",
	props: {},
	components: { CollapsePanel, CardAutocomplete, SideInfo, CardFunnel },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
	},
	computed: {
		...mapGetters("audience", [
			"getAudience"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters",
			getStrategy: "getStrategy"
		}),

		totalReach() {
			return this.getAudience.total_reach
		},

		proximityToPoi() {
			const source = this.getAudience.proximity_to_poi.source.map(item => ({
				...item,
				type: "poi_distance"
			}));

			return {
				...this.getAudience.proximity_to_poi,
				source
			}
		},

		totalReachFilter() {
			return this.totalReach.source.map(item => {
				item.translation = translateChartIfExist("user_type", item.name);

				return item
			})
		},

		filterTotalReach: {
			get() {
				return this.getFilters('user_type').map(item => {
					return { ...item, translation: translateChartIfExist(item.type, item.name) }
				})
			},
			set(event: FilterItem[] | FilterItem) {
				let data = event;
				if (isArray(event)) {
					data = event.map(item => ({ name: item.name, type: 'user_type' }));
				} else if (event) {
					data = {
						type: 'user_type',
						name: event.name
					};
				}

				this.addFilter({
					id: "user_type",
					data
				});
			}
		},
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),

		getReach(name: string) {
			return this.totalReach.source.find(item => item.name == name)
		},

		getIcon(name: string) {
			const icons = {
				"maid": "mdi-cellphone",
				"cookie": "mdi-cookie",
				"hashed": "mdi-pound",
				"undefined": "mdi-account"
			}

			return icons[name] || icons.undefined;
		}
	},
	watch: {},
});
