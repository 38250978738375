var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showCard)?_c('div',[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('section-separator',{attrs:{"icon":"mdi-calendar-range-outline","title":_vm.$t('Persons10.panelAudience.titles.date')}}),_c('v-row',{ref:"date_day_time"},[_c('v-col',[_c('card-line',{attrs:{"infoData":{
						id: 'date',
						name: _vm.$t(
							'Persons10.panelAudience.charts.titles.date'
						),
					},"datepicker":"","loading":_vm.date.loading,"hide_filter":"","source":_vm.date.source,"filters":_vm.getFilters('date')},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-line',{attrs:{"infoData":{
						id: 'date_of_week',
						name: _vm.$t(
							'Persons10.panelAudience.charts.titles.day_of_week'
						),
					},"hide_filter":"","loading":_vm.date_of_week.loading,"source":_vm.date_of_week.source}})],1),_c('v-col',[_c('card-line',{attrs:{"infoData":{
						id: 'time_of_day',
						name: _vm.$t(
							'Persons10.panelAudience.charts.titles.hour_of_day'
						),
					},"hide_filter":"","loading":_vm.time_of_day.loading,"source":_vm.time_of_day.source}})],1)],1),_c('div',{staticClass:"chart-section"},[_c('card-heatmap',{attrs:{"infoData":{
					id: 'frequency',
					name: _vm.$t(
						'Persons10.panelAudience.charts.titles.heatmap'
					),
				},"labels":['key1', 'key2'],"loading":_vm.time_of_day_of_week.loading,"filters":_vm.getFilters('time_of_day_of_week'),"source":_vm.time_of_day_of_week.source},on:{"filter":_vm.addFilter}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }