var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dialog-default',{attrs:{"persistentDialog":true,"dialog":_vm.show,"btnText":`${_vm.$t('persons.v10.exports.audience.title')}`,"btnClass":"mr-1 mb-1","outlined":true,"maxWidth":1100,"isXSmall":true,"activation":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"ml-4 my-4 font-weight-black secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("persons.v10.exports.audience.title"))+" ")])]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"grey lighten-3 pt-4",attrs:{"elevation":"0","justify":"start","align":"center"}},[_c('v-container',{staticClass:"mx-2"},[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex"},[_c('CardTextField',{attrs:{"rules":_vm.rule_name,"hint":_vm.$t('persons.v10.exports.audience.segment'),"reference":"name","placeholder":_vm.$t('persons.v10.exports.audience.segment'),"label":_vm.$t('persons.v10.exports.audience.segment'),"required":true,"error_messages":_vm.getErrorMessage('segment_name'),"counter":false},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('CardAutocomplete',{attrs:{"items":_vm.getAdvertisers,"item_text":"value","item_value":"id","reference":"advertiser","persistent_hint":true,"hint":_vm.$t(
									'persons.v10.exports.audience.advertiser_hint'
								).toString(),"label":_vm.$t(
									'persons.v10.exports.audience.advertiser'
								).toString(),"placeholder":_vm.$t(
									'persons.v10.exports.audience.advertiser'
								).toString(),"error_messages":_vm.getErrorMessage('account')},on:{"input":function($event){return _vm.updateCategories()},"click:clear":function($event){return _vm.updateCategories()}},model:{value:(_vm.advertiser_id),callback:function ($$v) {_vm.advertiser_id=$$v},expression:"advertiser_id"}})],1)]),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"300px","overflow":"auto"}},[_c('v-treeview',{attrs:{"dense":"","hoverable":"","selectable":"","activatable":"","return-object":"","items":_vm.items},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1),_c('v-divider'),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"transparent"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t("common.labels.mandatory"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8","lg":"6"}},[_c('card-actions',{attrs:{"showSubmit":true,"showSave":false,"showSaveContinue":false,"showCancel":true},on:{"action":_vm.handleAction}})],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }