import Vue, { PropType } from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { IFilterData } from "../../../../Audience";
import { mapActions, mapGetters } from "vuex";
import { formatVisibleItems } from "../../../Utils";
import SectionSeparator from "../../../Common/SectionSeparator/index.vue";

export default Vue.extend({
	name: "AudiencePois",
	props: {},
	components: { CollapsePanel, CardGrouped, SectionSeparator },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		
		const { category_poi, sub_category_poi, marca_poi, dpto_poi, city_poi, barrio_poi } = this.getAudience;
		this.$watch(() => [category_poi, sub_category_poi, marca_poi, dpto_poi, city_poi, barrio_poi, this.showCard], async () => {
			await this.$nextTick()
			const items: HTMLCollection = this.$refs.poisinformation?.children;
			formatVisibleItems(items, [
				category_poi,
				sub_category_poi,
				marca_poi,
				dpto_poi,
				city_poi,
				barrio_poi
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience",
			"getStrategy"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", {
			getCountry: "getCountry"
		}),
		
		category_poi() { return this.getAudience.category_poi },
		sub_category_poi() { return this.getAudience.sub_category_poi },
		marca_poi() { return this.getAudience.marca_poi },
		dpto_poi() { return this.getAudience.dpto_poi },
		city_poi() { return this.getAudience.city_poi },
		barrio_poi() { return this.getAudience.barrio_poi },

		showCard() {
			return this.getStrategy.hasKey('category_poi', 'sub_category_poi', 'marca_poi', 
			'dpto_poi', 'city_poi', 'barrio_poi') as boolean
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),

		// Get the translation of the geographies
		geoTranslate(key: "states"|"cities"|"neighborhoods") {
			const id = this.getCountry.id;
			const default_country_id = 840;
			const country_id = isNaN(id) ? default_country_id : id;
			const geoTranslated = this.$te(`persons.v10.panelsPois.geo.${country_id}.${key}`) 
				? this.$t(`persons.v10.panelsPois.geo.${country_id}.${key}`)
				: this.$t(`persons.v10.panelsPois.geo.${default_country_id}.${key}`);
			return this.$t(`Persons10.panelAudience.charts.titles.by_pois_information`, {
				name: geoTranslated
			})
		}
	},
	watch: {},
});
