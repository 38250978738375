import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import { formatVisibleItems } from "../../../Utils";
import SectionSeparator from "../../../Common/SectionSeparator/index.vue";

export default Vue.extend({
	name: "ConnectionType",
	props: {},
	components: { CollapsePanel, CardGrouped, SectionSeparator },
	data: () => ({}),
	created() { },
	mounted() {
		this.$nextTick(async () => { 
		 });
		 const { carrier, device_type, make, browser, os } = this.getAudience;
		this.$watch(() => [carrier, device_type, make, browser, os, this.showCard], async() => {			
			await this.$nextTick();
			const items: HTMLCollection = this.$refs.connection_type?.children;
			formatVisibleItems(items, [
				carrier, 
				device_type, 
				make, 
				browser, 
				os
			])			
		}, { immediate: true, deep: true })
	},
	computed: {
		...mapGetters("audience", [
			"getAudience",
			"getStrategy"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		
		carrier() { return this.getAudience.carrier },
		device_type() { return this.getAudience.device_type },
		make() { return this.getAudience.make },
		browser() { return this.getAudience.browser },
		os() { return this.getAudience.os },

		showCard() {
			return this.getStrategy.hasKey('carrier', 'device_type', 'make', 'browser', 'os') as boolean
		}
	},
	methods: {
		...mapActions("audience", [
			"addFilter", "removeFilter"
		]),
	},
	watch: {},
});
