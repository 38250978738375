import { FloatingAction } from "@/interfaces/persons/v10/audience";
import Vue, { PropType } from "vue";

export default Vue.extend({
	name: "FloatingActions",
	model: {},
	props: {
    items: {
      type: Array as PropType<FloatingAction[]>,
      default: []
    }
	},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {},
	methods: {
    handleAction(action: string) {
      this.$emit("click", action);
    }
  },
	watch: {},
});
