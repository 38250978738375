import { OrderOption, SortOption } from "@/interfaces/audience";
import { ItemGraphic } from "@/interfaces/graphic";
import { SortingOption } from "@/interfaces/paginated";
import { groupBy, head, orderBy } from "lodash";

/**
 * Get ordered data
 * @param source
 * @param filter
 * @returns
 */
export async function getSourceOrdered(
	source: Array<ItemGraphic>,
	filter: SortingOption = {
		order: OrderOption.DESC,
		sort: SortOption.UNIQ,
	}
) {
	if (!filter.sort) return source;
	/** TODO revisar order
	 * invertir el orden porque el componente del grafico invierte el order por defecto
	 */
	//@ts-ignore
	return orderBy(source, [filter.sort], [filter.order]);
}

/**
 * Get top data
 * @param source
 * @param top
 * @returns
 */
export async function getSourceTop(
	source: Array<ItemGraphic>,
	top: number | undefined
) {
	if (!top) return source;

	//@ts-ignore
	if (top > 0 && source.length > top) {
		return source.slice(0, top);
	}

	return source;
}

/**
 * Prepare grouped source
 * @param source
 * @returns
 */
export async function prepareGroupedSource(
	source: Array<ItemGraphic>,
	filter?: SortingOption,
	top?: any
) {
	/**
	 * Agrupar por type
	 */
	let groupByType = groupBy(source, "type");

	/**
	 * Obtener type unicos
	 */
	const typeUnique = Object.keys(groupByType);

	/**
	 * Preparar datos para matcheo de categorias
	 */

	// match: type: name
	let matchedTypeName = {};

	// match: type: index
	let matchedTypeIndex = {};

	const orderedSortedSources = typeUnique.map(async (val, i) => {
		let topSource = groupByType[val];

		/**
		 * Ordenar por uniques desc para obtener el top
		 */
		const sortedTop = await makeSortedSource({
			source: topSource,
			filter: {
				order: OrderOption.DESC,
				sort: SortOption.UNIQ,
			},
			top: top,
		});

		/**
		 * Se filtran por cada iteracion dependiendo del type
		 */
		let orderedSource: ItemGraphic[] = [];

		if (filter && !!filter?.sort) {
			orderedSource = await getSourceOrdered(sortedTop, filter);
		} else {
			orderedSource = await getSourceOrdered(sortedTop, {
				order: OrderOption.ASC,
				sort: "",
			});
		}

		const getHeadGroupByValue: ItemGraphic | undefined =
			head(orderedSource);

		var matchTypeName = [] as Array<any>;
		var matchTypeIndex = [] as Array<any>;

		matchTypeName[val] = getHeadGroupByValue?.name;
		matchTypeIndex[val] = i;

		Object.assign(matchedTypeName, matchTypeName);
		Object.assign(matchedTypeIndex, matchTypeIndex);

		return orderedSource;
	});

	const orderedSortedSource = await Promise.all(orderedSortedSources);
	const sourceResult = orderedSortedSource.flat().reverse();

	return {
		orderedSource: sourceResult, // reverse: porque el componente del grafico (libreria) invierte el orden de la colleccion
		typeUnique,
		matchedTypeName,
		matchedTypeIndex,
	};
}

/**
 * Prepare default source
 * @param source
 * @returns
 */
export async function prepareDefaultSource(
	source: Array<ItemGraphic>,
	filter?: SortingOption,
	top?: any
) {
	/**
	 * Agrupar por type
	 */
	let groupByType = groupBy(source, "type");

	/**
	 * Obtener type unicos
	 */
	const typeUnique = Object.keys(groupByType);

	/**
	 * Preparar datos para matcheo de categorias
	 */

	// match: type: name
	let matchedTypeName = {};

	// match: type: index
	let matchedTypeIndex = {};

	const orderedSortedSources = typeUnique.map(async (val, i) => {
		let source = groupByType[val];

		const getHeadGroupByValue: ItemGraphic | undefined =
			head(source);

		var matchTypeName = [] as Array<any>;
		var matchTypeIndex = [] as Array<any>;

		matchTypeName[val] = getHeadGroupByValue?.name;
		matchTypeIndex[val] = i;

		Object.assign(matchedTypeName, matchTypeName);
		Object.assign(matchedTypeIndex, matchTypeIndex);

		return source;
	});

	const orderedSortedSource = await Promise.all(orderedSortedSources);
	const sourceResult = orderedSortedSource.flat();

	return {
		orderedSource: sourceResult,
		typeUnique,
		matchedTypeName,
		matchedTypeIndex,
	};
}

export async function makeSortedSource(params: {
	source: ItemGraphic[];
	filter: SortingOption;
	top: number | undefined;
}) {
	let sortedTop = await getSourceOrdered(
		params.source,
		params.filter || {
			order: OrderOption.DESC,
			sort: SortOption.UNIQ,
		}
	);

	if (params.top && params.top > 0 && sortedTop.length > params.top) {
		sortedTop = sortedTop.slice(0, params.top);
	}

	return sortedTop;
}
