import Vue from "vue";

export default Vue.extend({
	name: "SectionSeparator",
	model: {},
	props: {
    title: String,
		icon: String,
	},
	components: {},
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {},
	methods: {},
	watch: {},
});
