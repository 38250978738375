import { debounce } from "lodash";
import Vue from "vue";
//@ts-ignore
import { SortingOption } from "@/interfaces/paginated";

import { getTopDefault, getTops } from "../utils";

export default Vue.extend({
	name: "FilterMenu",
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		sort: {
			type: Object,
			default: function () {
				return {
					label: "name",
					order: "asc",
				};
			},
		},
		top: {
			type: Object,
			default: function () {
				return {};
			},
		},
		id: {
			type: String,
		},
		show: {
			type: Boolean,
			default: true
		},
		showTop: {
			type: Boolean,
			default: true
		},
		configTop: {
			type: Object,
			default: function() {
				return {
					show: false,
					label: false,
					disabled: false
				}
			}
		}
	},
	components: {},
	data: function () {
		return {
			tops: [],
		};
	},
	created() {
		this.$nextTick(() => {
			this.tops = getTops();
		});
	},
	mounted() {},
	computed: {
		computedTop: {
			async set(val) {
				this.$emit("input", {
					id: this.id,
					filter: this.filter,
					top: val ?? await getTopDefault(0),
				});
			},
			get() {
				return this.top;
			},
		},
		getIcon() {
			if (!this.filter.sort) return "mdi-sort";
			return `mdi-sort-alphabetical-${this.filter.order}ending`;
		}
	},
	methods: {
		async initializeFilter() {
			return {
				sort: "",
				order: "asc",
			} as SortingOption;
		},
		handleClose: debounce(async function () {
			this.$emit("input", {
				id: this.id,
				filter: this.filter,
				top: await getTopDefault(0),
			});
		}, 500)
		// handleRemove: debounce(async function () {
		// 	this.$emit("input", {
		// 		id: this.id,
		// 		filter: await this.initializeFilter(),
		// 		top: this.computedTop,
		// 	});
		// }, 500),
	},
});
