import { IFrequency, IFrequencyItem } from "@/interfaces/persons/v10/audience"

/**
 * Allowed country codes to be filtered by socioeconomic
 */
export const allowedFiltrableSocioeconomic = [
  484,  // México
  32,   // Argentina
  152,  // Chile
  604,  // Peru
  170,  // Colombia
  218,  // Ecuador
]

/**
 * A list of saved frequencies, selected by it's saved frequency id
 * 
 * @pos0 startRow
 * @pos1 endRow
 * @pos2 startCol
 * @pos3 endCol
 */
export const matchedSavedFrequencies = [
  [[0, 23, 1, 7]], // All days
  [[12, 19, 1, 7]], // Afternoons
  [[19, 23, 1, 7], [0, 5, 1, 7]], // Nights
  [[6, 12, 1, 7]], // Mornings
  [[0, 23, 2, 6]], // Working days
  [[0, 23, 1, 1], [0, 23, 7, 7]] // Weekends
]

export function convertItemToFrequency(items: IFrequencyItem[]): IFrequency {
  const frequency = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: []
  }

  items.forEach(item => {
    const { index, name, ...rest } = item;
    Object.keys(rest).forEach(key => {
      if(item[key]) {
        frequency[key].push(index);
      }
    })
  })

  return frequency;
}