import Vue from "vue";
import CollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CollapsePanel/index.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import Buttons from "@/views/Admin/Commons/Buttons.vue";
import { mapActions, mapGetters } from "vuex";
import { isRequired } from "@/services/rule-services";
import { TypeLoading } from "@/interfaces/loading";
import { isEmpty, isNull } from "lodash";
import { AnalyzeAudienceType, AnalyzeType } from "@/interfaces/persons/v10/types";
import reportService from "@/services/report-service";

export default Vue.extend({
	name: "GenderAndAge",
	props: {},
	components: { CollapsePanel, CardAutocomplete, Buttons },
	data: () => ({
		rules: {
			type: [],
			format: []
		},
		selected_type: null,
		selected_format: null,
		formats: []
	}),
	created() { },
	mounted() {
		this.$nextTick(async () => { });
		this.getReportFormats()
	},
	computed: {
		...mapGetters("audience", [
			"getAudience",
			"getStrategy"
		]),
		...mapGetters("audience", {
			getFilters: "getFilters"
		}),
		...mapGetters("person", [
			"getCampaigns"
		]),

		tables() { return this.getAudience.tables },

		getItems() {
			const type = this.hasCampaigns ? "attribution" : "audience";

			return this.tables.source.map(key => ({
				value: key,
				text: this.$t(`Persons10.charts.tables.${type}.${key}`)
			}))
		},

		hasCampaigns() {
			return !isEmpty(this.getCampaigns);
		},

		getFormats() {
			return this.formats;
		},

		showCard() {
			return !this.getStrategy.sections.some(item => !item.selected) && this.getStrategy.type === AnalyzeAudienceType.POIS
		}
	},
	methods: {
		...mapActions("audience", ["fetchAll"]),
		...mapActions("download", ["downloadFile"]),
		...mapActions("loading", ["setLoadingData"]),

		async getReportFormats() {
			const response = await reportService.search({
				type: "report_format_types"
			})

			if(!isEmpty(response)) {
				this.formats = response;
			}
		},

		async handleDownload() {
			this.applyRules();
			await this.$nextTick();

			if(await this.validate()) {
				await this.setLoadingData(TypeLoading.loading);

				const params = {
					filters: {
						type: this.selected_format
					},
					title: this.selected_type,
					type: this.selected_type,
					extension: this.selected_format,
					method: "POST"
				};

				try {
					await this.downloadFile(params);
				} catch(e) {
					console.error(e);
				}

				await this.setLoadingData();
			}
		},

		applyRules() {
			this.rules = {
				type: [isRequired],
				format: [isRequired]
			}
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return valid;
	},
	},
	watch: {
		selected_type(currentVal: string|null) {
			if(isNull(currentVal)) {
				this.selected_format = null;
			} else {
				if(this.getFormats[0]) {
					this.selected_format = this.getFormats[0].extra
				}
			}
		},
		
		"getStrategy.isAllSectionSelected"() {
			this.fetchAll(['tables']);
		}
	},
});
