var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showCard)?_c('div',[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('section-separator',{attrs:{"icon":"mdi-human-male-female","title":_vm.$t('Persons10.panelAudience.titles.socio_demographic')}}),_c('v-row',{ref:"sociodemographic"},[_c('v-col',[_c('card-combined',{attrs:{"infoData":{
					id: 'gender',
					name: _vm.$t('Persons10.panelAudience.charts.titles.gender'),
				},"loading":_vm.gender.loading,"filters":_vm.getFilters('gender'),"source":_vm.gender.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-combined',{attrs:{"infoData":{
					id: 'age',
					name: _vm.$t('Persons10.panelAudience.charts.titles.age'),
				},"loading":_vm.age.loading,"filters":_vm.getFilters('age'),"source":_vm.age.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-combined',{attrs:{"infoData":{
					id: 'niv_socio',
					name: _vm.$t('Persons10.panelAudience.charts.titles.socio_economic')
				},"loading":_vm.niv_socio.loading,"hide_filter":!_vm.isFiltrableSocioeconomic,"filters":_vm.getFilters('niv_socio'),"source":_vm.niv_socio.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }