import { ResultElementData } from "@/interfaces/paginated";
import { PanelItem } from "@/interfaces/persons/v10/response";
import {
	ActivePanelTab,
	PersonGeoKey,
	PersonKey,
	PersonPoisKey,
} from "@/interfaces/persons/v10/types";
import {
	ExpansionPanelEntity,
	GraphicEntity,
} from "@/models/persons/v10/Implements";
import { ConfigPaginateEntity } from "@/models/persons/v10/response";
import { isEmpty, isUndefined } from "lodash";

export async function getExpansionPane(): Promise<ExpansionPanelEntity[]> {
	return [
		new ExpansionPanelEntity({
			id: ActivePanelTab.COUNTRY_GLOBAL,
			title: "persons.v10.panelsPois.titles.pais",
			icon: "mdi-web",
			type: PersonKey.COUNTRY_GLOBAL,
			disabled: false,
			expandable: false,
			required: true,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.STRATEGY,
			title: "persons.v10.panelsPois.titles.strategy",
			icon: "mdi-strategy",
			type: PersonKey.STRATEGY,
			disabled: false,
			expandable: false,
			required: true,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.USES_CASES,
			title: "persons.v10.panelsPois.titles.uses_cases",
			icon: "mdi-target",
			type: PersonKey.USES_CASES,
			disabled: true,
			expandable: false,
			required: true,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.GEO,
			title: "persons.v10.panelsPois.titles.geo",
			icon: "mdi-map-marker-outline",
			type: PersonKey.GEO,
			disabled: true,
			expandable: false,
			required: false,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.POIS,
			title: "persons.v10.panelsPois.titles.point_inter",
			icon: "mdi-map-marker-outline",
			type: PersonKey.POIS,
			disabled: true,
			expandable: false,
			required: false,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.OOH,
			title: "persons.v10.panelsPois.titles.ooh",
			icon: "mdi-map-marker-outline",
			type: PersonKey.OOH,
			disabled: true,
			expandable: false,
			required: false,
			loading: false,
			verify: true,
		}),
		new ExpansionPanelEntity({
			id: ActivePanelTab.PRIVATES,
			title: "persons.v10.panelsPois.titles.private",
			icon: "mdi-map-marker-outline",
			type: PersonKey.PRIVATE,
			disabled: true,
			expandable: false,
			required: false,
			loading: false,
			verify: true,
		}),
	];
}

export async function getSubPanelGeo() {
	return [
		{
			id: 0,
			title: "Persons10.panelsPois.subPanel.geo.estado",
			key: PersonGeoKey.STATES,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "codigo",
				value: "nombre",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		{
			id: 1,
			title: "Persons10.panelsPois.subPanel.geo.cuidades",
			key: PersonGeoKey.CITIES,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "codigo",
				value: "nombre",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		{
			id: 2,
			title: "Persons10.panelsPois.subPanel.geo.barrio",
			key: PersonGeoKey.NEIGHBORHOODS,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "codigo",
				value: "nombre",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
	];
}

export async function getSubPanelPointInter() {
	return [
		{
			id: 0,
			title: "Persons10.panelsPois.subPanel.point_inter.categoria",
			key: PersonPoisKey.CATEGORIES,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "nombre",
				value: "nombre",
				count: "count",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		{
			id: 1,
			title: "Persons10.panelsPois.subPanel.point_inter.subcategoria",
			key: PersonPoisKey.SUBCATEGORIES,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "nombre",
				value: "nombre",
				count: "count",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		{
			id: 2,
			title: "Persons10.panelsPois.subPanel.point_inter.marcas",
			key: PersonPoisKey.BRANDS,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "nombre",
				value: "nombre",
				count: "count",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		{
			id: 3,
			title: "Persons10.panelsPois.subPanel.point_inter.nombres",
			key: PersonPoisKey.NAMES,
			resultData: {} as ResultElementData,
			loading: false,
			config: {
				key: "nombre",
				value: "nombre",
				count: "count",
			},
			paginate: new ConfigPaginateEntity(),
		} as PanelItem,
		// {
		// 	id: 4,
		// 	title:
		// 		"Persons10.panelsPois.subPanel.point_inter.pois_privados"
		// 	),
		// 	key: PersonPoisKey.PRIVATES,
		// 	items: [] as ElementData[],
		// 	loading: false,
		// 	config: {
		// 		key: "codigo",
		// 		value: "nombre",
		// 	},
		// 	paginate: new ConfigPaginateEntity(),
		// } as PanelItem,
	];
}

export async function getParamsCategoria(params: any, attr: any) {
	return {
		country_code: params.country_code,
		audience_type: params.audience_type,
		filters: {
			geography: [
				{
					codigo_estado: isEmpty(params.codigo_estado)
						? undefined
						: params.codigo_estado,
					codigo_ciudad: isEmpty(params.codigo_municipio)
						? undefined
						: params.codigo_municipio,
					codigo_barrio: isEmpty(params.codigo_barrio)
						? undefined
						: params.codigo_barrio,
				},
			],
		},
		mode: attr.mode,
		limit: attr.limit,
		page: attr.page,
		search: attr.search,
	};
}

export async function getParamsSubCategoria(params: any, attr: any) {
	return {
		country_code: params.country_code,
		audience_type: params.audience_type,
		filters: {
			geography: [
				{
					codigo_estado: isEmpty(params.codigo_estado)
						? undefined
						: params.codigo_estado,
					codigo_ciudad: isEmpty(params.codigo_municipio)
						? undefined
						: params.codigo_municipio,
					codigo_barrio: isEmpty(params.codigo_barrio)
						? undefined
						: params.codigo_barrio,
				},
			],
			categoria: params.categoria,
		},
		mode: attr.mode,
		limit: attr.limit,
		page: attr.page,
		search: attr.search,
	};
}

export async function getParamsMarca(params: any, attr: any) {
	return {
		country_code: params.country_code,
		audience_type: params.audience_type,
		filters: {
			geography: [
				{
					codigo_estado: isEmpty(params.codigo_estado)
						? undefined
						: params.codigo_estado,
					codigo_ciudad: isEmpty(params.codigo_municipio)
						? undefined
						: params.codigo_municipio,
					codigo_barrio: isEmpty(params.codigo_barrio)
						? undefined
						: params.codigo_barrio,
				},
			],
			categoria: params.categoria,
			subcategoria: params.subcategoria,
		},
		mode: attr.mode,
		limit: attr.limit,
		page: attr.page,
		search: attr.search,
	};
}

export async function getParamsName(params: any, attr: any) {
	return {
		country_code: params.country_code,
		audience_type: params.audience_type,
		filters: {
			geography: [
				{
					codigo_estado: isEmpty(params.codigo_estado)
						? undefined
						: params.codigo_estado,
					codigo_ciudad: isEmpty(params.codigo_municipio)
						? undefined
						: params.codigo_municipio,
					codigo_barrio: isEmpty(params.codigo_barrio)
						? undefined
						: params.codigo_barrio,
				},
			],
			categoria: params.categoria,
			subcategoria: params.subcategoria,
			marca: params.marca,
		},
		mode: attr.mode,
		limit: attr.limit,
		page: attr.page,
		search: attr.search,
	};
}

export function countVisibleItems(items: GraphicEntity[]) {
	if (!items) return 0;
	let count = 0;

	items.forEach((item) => {
		if (item.loading || !isEmpty(item.source)) {
			return count++;
		}
	});

	return count;
}

export function formatVisibleItems(
	elems: HTMLCollection,
	items: GraphicEntity[]
) {
	if(isUndefined(elems)) return;

	const visibles = countVisibleItems(items);

	const newElems = Array.from(elems).filter((elem) => {
		if (visibles === 0) return false;
		let validation = elem.children.length > 0;

		if (!validation) {
			elem.className = "d-none";
		}

		return validation;
	});

	const remainderXl = newElems.length % 3;
	const remainderMd = newElems.length % 2;

	newElems.map((elem, index) => {
		elem.className = "d-block col col-md-6 col-xl-4 col-12";

		if (remainderXl > 0 && index >= Math.floor(newElems.length / 3) * 3) {
			elem.classList.remove(`col-xl-4`);
			elem.classList.add(`col-xl-${12 / remainderXl}`);
		}

		if (remainderMd > 0 && index >= Math.floor(newElems.length / 2) * 2) {
			elem.classList.remove(`col-md-6`);
			elem.classList.add(`col-md-12`);
		}
	});
}
