import i18n from '@/plugins/i18n';
import { isUndefined } from 'lodash'

declare global {
    interface Window {
        // IE navigator lanuage settings (non-standard)
        userLanguage: string;
        browserLanguage: string;
    }
}

export function getLocale(): string {
    let locale = 'en'
    
    if (isUndefined(window) || isUndefined(window.navigator)) {
        return locale
    }

    //locale = window.navigator.language || window.browserLanguage || window.userLanguage

    locale = parseLocale(locale)

    //return locale
    return localStorage.name;
}

function parseLocale(locale: string) {
    let pl = 'en'

    if (locale.startsWith('es')) {
        pl = 'es'
    }

    if (locale.startsWith('pt')) {
        pl = 'pt'
    }

    return pl
}

export function translateChartIfExist(key: string, value: any) {
    if(i18n.te(`Persons10.charts.${key}.${value}`)) {
        return i18n.t(`Persons10.charts.${key}.${value}`)
    } else {
        return value        
    }
}

export default getLocale()
