import Vue from "vue";
import { mapGetters } from "vuex";

import FilterMenu from "@/views/Admin/Persons/V10/Dependencies/Filters/Menu/index.vue";

import { ItemGraphic } from "@/interfaces/graphic";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import Frequency from "@/views/Admin/Persons/V10/Dependencies/Dialogs/Frequency/index.vue"
import { PropType } from "vue/types/options";
import { translateChartIfExist } from "@/utils/Locale";
import { isArray, max, min, orderBy, sortBy } from "lodash";
import { FilterItem } from "@/interfaces/persons/v10/audience";

export default Vue.extend({
	name: "CardChart",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#3a4972",
		},
		filters: {
			type: Array,
			default: function () {
				return [];
			},
		},
		source: {
			type: Array,
			default: function () {
				return [] as Array<ItemGraphic>;
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
		sort: {
			type: Object,
			default: () => ({
				label: "uniques" as "uniques" | "name",
				order: "asc" as "asc" | "desc"
			})
		},
		name: {
			type: String,
			required: true
		},
		allowedLabels: {
			type: Array as PropType<('name' | 'uniques')[]>,
			default: () => (['name', 'uniques'])
		},
		labels: {
			type: Array as PropType<string[]>,
			default: () => ([])
		},
		hide_filter: {
			type: Boolean,
			default: false
		},
		datepicker: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: true
		}
	},
	components: { FilterMenu, CardAutocomplete, CardTextField, Frequency },
	data: () => ({
		expanded: false,
		currentValue: [],
		dates: null,
		menu: false,
		modal: false
	}),
	created() { },
	mounted() { },
	computed: {
		...mapGetters("loading", ["isLoading"]),

		valuesSource() {
			return this.source.map(item => item.name);
		},

		filterList() {
			const items = this.source.map(item => {
				if(!item.name) return;
				return { name: item.name, uniques: item.uniques, translation: translateChartIfExist(this.infoData.id, item.name) }
			}).filter(Boolean)
			return orderBy(
					items, 
					[this.hide_filter ? "name" : this.sort.label], 
					[this.hide_filter ? "asc" : this.sort.order === "asc" ? "desc" : "asc"]
					);
		},

		currentFilter: {
			get() {
				if(!this.multiple && this.filters.length) {
					const item = this.filters[0]
					return { ...item, translation: translateChartIfExist(this.infoData.id, item.name) }
				}

				return this.filters.map(item => {
					return { ...item, translation: translateChartIfExist(this.infoData.id, item.name) }
				})
			},

			set(event: FilterItem[] | FilterItem | null) {
				let data = event;
				if (isArray(event)) {
					data = event.map(item => ({ name: item.name, type: this.infoData.id }));
				} else if (event) {
					data = {
						type: this.infoData.id,
						name: event.name
					};
				}

				this.$emit("filter", {
					id: this.infoData.id,
					data
				});
			}
		},

		minDate() {
			return min(this.valuesSource)
		},

		maxDate() {
			return max(this.valuesSource)
		}
	},
	methods: {

		handleCancel() {
			this.modal = false;
		},
		openDatePicker() {
			const dates = (this.filters && this.filters[0]) ? this.filters[0].name.split(", ") : null
			this.dates = dates;
			this.modal = true;
		},
		saveDate() {
			if (this.dates) {
				if (this.dates.length === 1) {
					this.$emit("filter", {
						id: this.infoData.id,
						data: {
							type: this.infoData.id,
							name: `${this.dates[0]}, ${this.dates[0]}`
						}
					})
				} else {
					this.$emit("filter", {
						id: this.infoData.id,
						data: {
							type: this.infoData.id,
							name: this.dates.sort().join(", ")
						}
					})
				}
			}

			this.modal = false;
		},
		allowedDates(value: string) {
			if (!this.valuesSource) return false;
			return this.valuesSource.indexOf(value) !== -1;
		},
		saveFrequency(value: any) {
			this.$emit("filter", value);
			this.modal = false;
		}
	},
	watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},
	},
});
