import Vue, { PropType } from "vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import Alertize from "@/components/Alertize.vue";
import { isEqual, findIndex, isNumber, isUndefined, isEmpty, isNull } from "lodash";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { convertItemToFrequency, matchedSavedFrequencies } from "../../Tabs/Audience/utils";
import { ItemGraphic } from "@/interfaces/graphic";
import chroma from "chroma-js";
import { FilterItem, IFrequencyItem } from "@/interfaces/persons/v10/audience";

const getInitialData = () => Array(24).fill("").map((_, index) => ({
	name: `Persons10.charts.time_of_day.${index}`,
	index,
	"1": false,
	"2": false,
	"3": false,
	"4": false,
	"5": false,
	"6": false,
	"7": false
}));

export default Vue.extend({
	name: "FrequencySelect",

	props: {
		show: {
			type: Boolean,
			default: false
		},
		source: {
			type: Array,
			default: function () {
				return [] as Array<ItemGraphic>;
			},
		},
		labels: {
			type: Array as PropType<string[]>,
			default: () => ([])
		},
	},

	components: {
		CardTextField,
		CardAutocomplete,
		Alertize,
    DialogDefault,
    CardActions
	},

	data: () => ({
		clicked: false,
		type: false,
		lastPressed: [0, 0],
		selectedFrequency: 6,
		items: [...getInitialData()],
		headers: [
			{ text: 'H', align: 'center', sortable: false, value: 'name' },
			{ text: 'Persons10.charts.date_of_week.1', sortable: false, value: '1' },
			{ text: 'Persons10.charts.date_of_week.2', sortable: false, value: '2' },
			{ text: 'Persons10.charts.date_of_week.3', sortable: false, value: '3' },
			{ text: 'Persons10.charts.date_of_week.4', sortable: false, value: '4' },
			{ text: 'Persons10.charts.date_of_week.5', sortable: false, value: '5' },
			{ text: 'Persons10.charts.date_of_week.6', sortable: false, value: '6' },
			{ text: 'Persons10.charts.date_of_week.7', sortable: false, value: '7' },
		],
		min: 0,
		max: 0,
		showFrequency: false,
	}),

	created() {},

	async mounted() {
		window.addEventListener("mousedown", this.onClickPress)
		window.addEventListener("mouseup", this.onClickPress)
	},

	computed: {
		savedFrequencies() {
			return [
				{ title: this.$t('timeItems.0'), action: "0" },
				{ title: this.$t('timeItems.1'), action: "1" },
				{ title: this.$t('timeItems.2'), action: "2" },
				{ title: this.$t('timeItems.3'), action: "3" },
				{ title: this.$t('timeItems.4'), action: "4" },
				{ title: this.$t('timeItems.5'), action: "5" },
				{ title: this.$t('timeItems.6'), action: "6" }
			]
		},

		getFrequencyValues() {
			const [day, hour] = this.labels;

			if(!day || !hour) return null;
			if(isEmpty(this.source)) return null;

			let min = Infinity;
			let max = 0;

			const items = {};
			this.source.map(item => {
				min = Math.min(item.uniques, min)
				max = Math.max(item.uniques, max)

				if(isUndefined(items[item[day]])) {
					items[item[day]] = {};
				}
				items[item[day]][item[hour]] = item.uniques
			})
				
			this.min = min;
			this.max = max;

			return items;
		},

		getSelectedFrequency: {
			get() {
				return this.selectedFrequency;
			},

			set(data: number) {
				if(isUndefined(data)) return;

				if(matchedSavedFrequencies[data]) {
					this.items = [...getInitialData()];
					
					matchedSavedFrequencies[data].map((frequency) => {
						const [rowStart, rowEnd, colStart, colEnd] = frequency;
						this.setFrequency(rowStart, rowEnd, colStart, colEnd, true);
					});
				}

				this.selectedFrequency = data;
			}
		}
	},

	methods: {
		onClickPress(data: MouseEvent) {
			this.clicked = data.type === "mousedown";
		},

		getColor(item: IFrequencyItem, col: number) {
			if(isNull(this.getFrequencyValues) || !this.showFrequency) {
				return item[col] ? "var(--v-secondary-base)" : "transparent"
			}

			const value = this.getFrequencyValues[col] ? this.getFrequencyValues[col][item.index] || 0 : 0;

			const color = this.getGradientColor(value, item[col]);

			return color;
		},

		setFrequency(rowStart: number, rowEnd: number, colStart: number, colEnd: number, force: boolean) {	
			for(let i = rowStart; i <= rowEnd; i++) {
				for(let j = colStart; j <= colEnd; j++) {
					this.items[i][j] = !isUndefined(force) ? force : this.type;
				}
			}			
		},

		selectAll(item: IFrequencyItem & {value: string}, type: "row"|"col") {
			this.selectedFrequency = 6;
			let isAllSelected = false;

			let startCol = 1;
			let endCol = 7;
			let startRow = 0;
			let endRow = 23;

			switch(type) {
				case 'row':
					const { name, index, ...rest } = item;

					startRow = index;
					endRow = index;

					isAllSelected = Object.values(rest).filter(value => !value).length === 0;

					
					break;
				case 'col':
					if(item.value === 'name') {
						const items = this.items.map(row => {
							const { name, index, ...rest } = row;

							return Object.values(rest);
						}).flat();
	
						isAllSelected = Object.values(items).filter(value => !value).length === 0;
					} else {
						const items = this.items.map(row => row[item.value]);
	
						startCol = parseInt(item.value);
						endCol = parseInt(item.value);
	
						isAllSelected = Object.values(items).filter(value => !value).length === 0;
					}
					break;
				default:
					break;
			}

			this.setFrequency(startRow, endRow, startCol, endCol, !isAllSelected);
		},

		handleClick(e: MouseEvent, item: IFrequencyItem, index: number) {
			const finded = this.findCurrentRow(item, index);
			this.selectedFrequency = 6;

			if(e.shiftKey && isNumber(this.lastPressed[0]) && !isUndefined(this.lastPressed[1])) {
				let rowStart = Math.min(finded, this.lastPressed[0]);				
				let rowEnd = Math.max(finded, this.lastPressed[0]);

				let colStart = Math.min(index, this.lastPressed[1]);
				let colEnd = Math.max(index, this.lastPressed[1]);

				this.setFrequency(rowStart, rowEnd, colStart, colEnd);

			} else {
				this.lastPressed = [finded, index];
			}
		},

		handleLeave(item: IFrequencyItem, index: number) {
			if(this.clicked) {
				item[index] = this.type;
			}
		},

    handleAction(data: {type: "submit"|"cancel"}) {
      if(data.type === "submit") {
        this.handleSubmit();
      }
      if(data.type === "cancel") {
        this.closeModal();
      }
    },

		hoverAction(_: MouseEvent, item: IFrequencyItem, index: number) {
			if(this.clicked) {
				this.selectedFrequency = 6;
				item[index] = this.type;
			}
		},

		setType(e: MouseEvent, item: IFrequencyItem, index: number) {
			if(e.shiftKey) return;
			
			this.selectedFrequency = 6;
			this.type = !item[index];
		},

		findCurrentRow(item) {
			return findIndex(this.items, (currentItem) => {
				return isEqual(currentItem, item);
			})
		},

		closeModal() {
			this.$emit("close");
		},

		async handleSubmit() {
			try {
				this.$emit("filter", {
					id: "frequency",
					data: {
						name: String(this.selectedFrequency),
						type: "frequency",
						extra: convertItemToFrequency(this.items)
					} as FilterItem,
					
				})
			} catch (error) {
				console.error(error);
			}
		},

		getGradientColor(value: number, checked: boolean) {
			const scaleColor = chroma.scale(['#FFFA76', '#FE131A']);
			const scaleGray = chroma.scale(['#FFFFFF', '#888888']);
			const percent = (value - this.min) / (this.max - this.min);

			return checked 
				? scaleColor(percent).hex()
				: scaleGray(percent).hex()
		}
	},

	watch: {},

	destroyed() {
		window.removeEventListener("mousedown", this.onClickPress)
		window.addEventListener("mouseup", this.onClickPress)
	}
});