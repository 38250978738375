import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { InfoData } from "@/interfaces/export";
import { getCellSize, newYAxisRenderer, optionsAxis, optionsYAxisRenderer } from "./index";
import AmchartGlobal from "./global";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const colorNext: number = 0;

class AmchartGrouped {
	root: any = null;
	exporting: any = null;

	async dispose(id: string) {
		am5.array.each(am5.registry.rootElements, function (root: am5.Root) {
			if (root?.dom?.id == id) {
				root.dispose();
			}
		});
	}

	async setup(params: {
		id: string;
		infoData: InfoData;
		source: Array<any>;
		typeUnique: any;
		matchedTypeName: any;
		matchedTypeIndex: any;
	}) {

		await this.dispose(params.id);

		if (!AmchartGlobal.isPassedSource(params.infoData.id, params.source))
			return;

		this.root = am5.Root.new(params.id);

		this.root.fps = 30;

		// Create chart
		var chart = this.root?.container?.children.push(
			am5xy.XYChart.new(this.root, {
				panX: false,
				panY: false,
				wheelX: "zoomX",
				wheelY: "panY",
				scrollbarY: am5.Scrollbar.new(this.root, {
					orientation: "vertical",
				}),
				pinchZoomX: false,
				layout: this.root.verticalLayout,
			})
		);

		// Remove zoom out button
		chart.zoomOutButton.set("forceHidden", true)

		// Add cursor
		chart.set(
			"cursor",
			am5xy.XYCursor.new(this.root, {
				xAxis: xAxis,
				yAxis: yAxis,
			})
		);

		// Add legend
		var legendData: Array<any> = [];

		var legend = chart?.children.push(
			am5.Legend.new(this.root, {
				nameField: "name",
				fillField: "color",
				strokeField: "color",
				marginLeft: 10,
				y: 10,
				layout: this.root.verticalLayout,
				clickTarget: "none",
			})
		);

		var xAxis = chart?.xAxes.push(
			am5xy.ValueAxis.new(this.root, {
				...optionsAxis,
				strictMinMax: true,
				renderer: am5xy.AxisRendererX.new(this.root, {}),
			})
		);

		// Create axes
		var yAxis = chart?.yAxes.push(
			am5xy.CategoryAxis.new(this.root, {
				...optionsAxis,
				categoryField: "name",
				renderer: am5xy.AxisRendererY.new(this.root, newYAxisRenderer),
			})
		);
		yAxis.get("renderer").labels.template.setAll(optionsYAxisRenderer);
		yAxis.data.setAll(params.source);

		// Add series
		var series = chart?.series.push(
			am5xy.ColumnSeries.new(this.root, {
				xAxis: xAxis,
				yAxis: yAxis,
				valueXField: "uniques",
				valueYField: "uniques",
				categoryYField: "name",
				columnWidth: 50,
				tooltip: am5.Tooltip.new(this.root, {
					pointerOrientation: "horizontal",
					labelText: "{categoryY}: [bold]{valueX}",
				}),
			})
		);

		// let cellSize = getCellSize;
		series.events.on("datavalidated", function (ev: { target: any }) {
			let series = ev.target;
			let chart = series.chart;
			// let xAxis = chart.xAxes.getIndex(0);

			// Calculate how we need to adjust chart height
			// let chartHeight =
			// 	series.data.length * cellSize +
			// 	xAxis.height() +
			// 	chart.get("paddingTop", 0) +
			// 	chart.get("paddingBottom", 20);

			// Set it on chart's container
			chart.root.dom.style.height = 400 + "px";

			yAxis.zoomToIndexes(series.data.length, series.data.length - 15)
		});

		series.bullets.push(function (root: am5.Root) {
			return am5.Bullet.new(root, {
				locationX: 0.5,
				locationY: 0.5,
				sprite: am5.Label.new(root, {
					fontSize: 10,
					centerX: am5.percent(0),
					centerY: am5.percent(50),
					text: "{valueX}",
					populateText: true,
				}),
			});
		});

		series.columns.template.setAll({
			tooltipText: "{categoryY}: [bold]{valueX}[/]",
			width: am5.p100,
			height: am5.percent(90),
			strokeOpacity: 0,
		});

		series.columns.template.adapters.add(
			"fill",
			function (fill: any, target: any) {
				if (target.dataItem) {
					const matchedTypeUnique = params.typeUnique.find(
						(t: any) => t == target.dataItem.dataContext.type
					);

					if (!matchedTypeUnique) return fill;

					const index = params.matchedTypeIndex[matchedTypeUnique];

					if (!index) return fill;

					return chart?.get("colors")?.getIndex(index + colorNext);
				}

				return fill;
			}
		);

		series.data.setAll(params.source);

		/**
		 * Functions
		 */

		function createRange(label: string, category: string, color: any) {
			var rangeDataItem = yAxis.makeDataItem({
				category: category,
			});

			var range = yAxis.createAxisRange(rangeDataItem);

			rangeDataItem.get("label").setAll({
				fill: color,
				text: label,
				location: 1,
				fontWeight: "bold",
				dx: -130,
			});

			rangeDataItem.get("grid").setAll({
				text: label,
				stroke: color,
				strokeOpacity: 1,
				location: 1,
			});

			rangeDataItem.get("tick").setAll({
				text: label,
				stroke: color,
				strokeOpacity: 1,
				location: 1,
				visible: true,
				length: 130,
			});

			//legendData.push({ name: label, color: color });
		}

		// params.typeUnique.map((type: string) => {
		// 	const index = params.matchedTypeIndex[type];
		// 	const name = params.matchedTypeName[type];
		// 	if (!type || type == "undefined") return;
		// 	createRange(
		// 		type,
		// 		name,
		// 		chart?.get("colors")?.getIndex(index + colorNext)
		// 	);
		// });

		// legend.data.setAll(legendData);

		this.load(series, chart);

		// Add export menu
		this.exporting = await AmchartGlobal.enableExporting(
			params.id,
			this.root,
			params.source
		);

		/**
		 * Se agrega la informacion del grafico para incluir en la generacion del pdf
		 */
		AmchartGlobal.addExporting(
			await AmchartGlobal.prepareExportParams(params, this.exporting)
		);
	}

	async load(series: am5xy.ColumnSeries, chart: am5xy.XYChart) {
		series.appear(500, 100);
		chart.appear(500, 100);
	}
}

export default new AmchartGrouped();