import Vue from "vue";

import AmchartGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/utils/grouped";
import { isEmpty } from "lodash";
import { ItemGraphic } from "@/interfaces/graphic";
import { prepareGroupedSource } from "../utils-global";
import { SortingOption } from "@/interfaces/paginated";
import { OrderOption } from "@/interfaces/audience";

const slug: string = "grouped";

export default Vue.extend({
	name: "GroupedChart",
	model: {
		event: "input",
		prop: "filter",
	},
	props: {
		source: {
			type: Array,
			default: function () {
				return [] as Array<ItemGraphic>;
			},
		},
		filter: {
			type: Object,
			default: function () {
				return {
					sort: "",
					order: OrderOption.DESC,
				};
			},
		},
		top: {
			type: Object,
			default: function () {
				return {};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		infoData: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {},
	data: () => ({
		dinamic_id: "",
	}),
	created() {
		this.$nextTick(async () => {
			this.dinamic_id = `${this.infoData.id}_${slug}`;
		});
	},
	async mounted() {
		try {
			if (isEmpty(this.source)) return;
			this.dinamic_id = `${this.infoData.id}_${slug}`;
			this.loadSource(this.source, this.filter, this.top);
		} catch (error) {
			console.error("loadSource", { error });
		}
	},
	computed: {
		modelFilter: {
			get() {
				return this.filter;
			},
			set(val) {},
		},
	},
	methods: {
		async loadSource(
			source: Array<any>,
			filter?: SortingOption,
			top?: number
		) {
			/**
			 * Estructura del array
			 * [{ type, name, unique }, { type, name, unique }, ...]
			 */

			const {
				orderedSource,
				typeUnique,
				matchedTypeName,
				matchedTypeIndex,
			} = await prepareGroupedSource(source, filter, top);

			AmchartGrouped.setup({
				id: this.dinamic_id,
				infoData: this.infoData,
				source,
				typeUnique,
				matchedTypeName,
				matchedTypeIndex,
			});
		},
	},
	watch: {
		async top(val) {
			if (!this.source.length) return;
			await AmchartGrouped.dispose(this.dinamic_id);
			this.loadSource(this.source, this.modelFilter, val?.key);
		},
		async source(val) {
			await AmchartGrouped.dispose(this.dinamic_id);
			this.loadSource(val, this.modelFilter, this.top?.key);
		},
		modelFilter: {
			async handler(val) {
				await AmchartGrouped.dispose(this.dinamic_id);
				this.loadSource(this.source, this.modelFilter, this.top?.key);
			},
			deep: true,
		},
	},
});
