import i18n from "@/plugins/i18n";

export const defaultTopLimits: any = {
	pois: 20,
	audience: 100,
};

export function getTops() {
	const topValues: Array<number> = [0, 20, 50, 100];
	return topValues.map((k) => {
		const translatedText = i18n.t("common.tops.0");
		return {
			key: k,
			value: k == 0 ? translatedText : i18n.t("common.tops.n", { n: k }),
		};
	});
}

export async function getTopDefault(limit: number) {
	const tops: Array<any> = getTops();
	return tops.find((t) => t.key == limit);
}

export const matchedFieldTabs = async (top: any) => {
	return {
		pois: await poisFields(top),
		audience: await audienceFields(top),
	};
};

export async function poisFields(top: any) {
	return {
		categories: top,
		cities: top,
		brands: top,
		names: top,
	};
}

export async function audienceFields(top: any) {
	return {
		users_by_distance: top,
		users_by_date: top,
		users_by_week_day: top,
		users_by_hour_day: top,
		users_by_category: top,
		users_by_subcategory: top,
		users_by_poi_city: top,
		users_by_dpto: top,
		users_by_poi_neighborhood: top,
		users_by_ciudad_residencia: top,
		users_by_niv_socio: top,
		users_by_age: top,
		users_by_gender: top,
		users_by_barrio_residencia: top,
		users_by_type: top,
		users_by_iab: top,
		users_by_intereses: top,
		users_by_app_name: top,
		users_by_domain: top,
		users_by_city_seen: top,
		users_by_carrier: top,
		users_by_device_make: top,
		users_by_platform_device_type: top,
		users_by_platform_browser: top,
		users_by_device_language: top,
		users_by_plataform_os: top,
	};
}

/**
 * Initialize tops
 * @returns
 */
export async function initializeTops(type: string) {
	const limit: number = defaultTopLimits[type];

	const top: any = await getTopDefault(limit);

	const matchedFieldTab = await matchedFieldTabs(top);

	const topFields = matchedFieldTab[type];

	return topFields;
}
