import Vue from "vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSearcher from "@/components/Content/CardSearcher.vue";
import { isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import { convLocaleString } from "@/utils/convert";

export default Vue.extend({
	name: "StoreAttributionTable",
	model: {},
	props: {
		textColor: {
			type: String,
			default: "#5958a5",
		}
	},
	components: {},
	data: () => ({
		expanded: false
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {},
	computed: {
		...mapGetters("person", ["getCampaigns"]),

		getStoreAttributionTable() {
			const campaigns: ComboListOptionsCampaign[] = this.getCampaigns || [];

			let headerKeys = ["id", "name", "start_date", "end_date", "impression", "clicks", "ctr"];

			let headers = headerKeys.map(key => {
				return {
					key,
					value: key,
					sortable: false,
					text: this.$t(`campaign.fields.${key}`)
				}
			})

			return {
				headers,
				items: campaigns
			}
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("download", ["downloadFile"]),
		colorClass(value: string) {
			let grad = 5;
			let type = "lighten";
			let color = "grey";

			if (value == "F_") {
				type = "lighten";
				grad = 3;
				color = "grey";
			}

			if (value == "M_") {
				type = "lighten";
				grad = 3;
				color = "grey";
			}

			return `${color} ${type}-${grad}`;
		},
		handlePagination(event: any) {
			this.itemsPerPage = event.itemsPerPage;
			this.page = event.page;
			this.pageCount = event.pageCount;
		},
		sumField(key: string) {
			return this.source?.items.reduce(
				(a: any, b: any) => a + (b[key] || 0),
				0
			);
		},
		async handleDownload() {
			await this.setLoadingData(TypeLoading.loading);

			const params = {
				filters: undefined,
				title: "users_by_pois_gender_age",
				type: "gender_age",
				method: "GET"
			};

			await this.downloadFile(params);

			await this.setLoadingData();
		}
	},
	watch: {},
});
