var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showCard)?_c('div',[_c('v-card',{staticClass:"gray lighten-3 px-5 pb-5",attrs:{"elevation":"0"}},[_c('section-separator',{attrs:{"icon":"mdi-sitemap-outline","title":_vm.$t('Persons10.panelAudience.titles.pois_information')}}),_c('v-row',{ref:"poisinformation"},[_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'category_poi',
						name: _vm.$t('Persons10.panelAudience.charts.titles.category'),
					},"loading":_vm.category_poi.loading,"filters":_vm.getFilters('category_poi'),"source":_vm.category_poi.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'sub_category_poi',
						name: _vm.$t('Persons10.panelAudience.charts.titles.subcategory'),
					},"loading":_vm.sub_category_poi.loading,"filters":_vm.getFilters('sub_category_poi'),"source":_vm.sub_category_poi.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'marca_poi',
						name: _vm.$t('Persons10.panelAudience.charts.titles.brand'),
					},"loading":_vm.marca_poi.loading,"filters":_vm.getFilters('marca_poi'),"source":_vm.marca_poi.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'dpto_poi',
						name: _vm.geoTranslate('states')
					},"loading":_vm.dpto_poi.loading,"filters":_vm.getFilters('dpto_poi'),"source":_vm.dpto_poi.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'city_poi',
						name: _vm.geoTranslate('cities')
					},"loading":_vm.city_poi.loading,"filters":_vm.getFilters('city_poi'),"source":_vm.city_poi.source},on:{"filter":_vm.addFilter}})],1),_c('v-col',[_c('card-grouped',{attrs:{"infoData":{
						id: 'barrio_poi',
						name: _vm.geoTranslate('neighborhoods')
					},"loading":_vm.barrio_poi.loading,"filters":_vm.getFilters('barrio_poi'),"source":_vm.barrio_poi.source},on:{"filter":_vm.addFilter}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }